export default {
    EVENTS_LIST: "Liste des évenements",
    ADD_EVENT: "Ajouter une évenement",
    EVENT_ADDED: "Evenement ajoutée",
    OWNER: "Propriétaire",
    EDIT_EVENT: "Modifier une évenement",
    EVENT_UPDATED: "Evenement mis à Jour",
    DELETE_THIS_EVENT: "Supprimer cette évenement ?",
    EVENT_DELETED: "Evenement Supprimée",
    EVENT_ACCEPTED: "Evenement Acceptée",
    VIEW_EVENT: "Evenement",
    }
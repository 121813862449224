import permissions from "../constants/permissions";
import AuctionsModuleLayout from "@/views/Pages/AuctionsModule/AuctionsModuleLayout.vue";
import AuctionsModuleMenu from "@/views/Pages/AuctionsModule/AuctionsModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const AuctionsPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionsPage.vue");

const ListPropertyPage = () =>
  import(
    "@/views/Pages/AuctionsModule/PropertyManagement/ListPropertyPage.vue"
  );

const ListAuctionPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionManagement/ListAuctionPage.vue");

const ListVehiclePage = () =>
  import(
/*     "@/views/Pages/AuctionsModule/VehicleManagementE/ListVehiclePage.vue" */
    "@/views/Pages/AuctionsModule/VehicleManagementE/ListVehiclePage.vue"
  );

let propertyManagementPages = {
  path: "properties",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Properties",
      components: { default: ListPropertyPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PROPERTIES,
      },
    },
  ],
};

let auctionManagementPages = {
  path: "auctions",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Auctions",
      components: { default: ListAuctionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_AUCTIONS,
      },
    },
  ],
};
/* NB:PERM_VIEW_ANY_PROPERTIES change it after add permission to user PERM_VIEW_ANY_VEHICLES */
let vehicleManagementPages = {
  path: "vehicles",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Vehicles",
      components: { default: ListVehiclePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PROPERTIES,
      },
    },
  ],
};

const AuctionModuleRoutes = [
  {
    path: "",
    components: {
      default: AuctionsModuleLayout,
      SidebarSecondLevelMenu: AuctionsModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Auctions Page",
        components: { default: AuctionsPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  propertyManagementPages,
  auctionManagementPages,
  vehicleManagementPages
];

export default AuctionModuleRoutes;

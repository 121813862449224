

import service from "@/store/services/vehicles-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  vehicle: {},
  meta: {},
  url: null,
  apiVehicles: {},
  apiVehiclesPrices: {},
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_API_VEHICLES: (state, apiVehicles) => {
    state.apiVehicles = apiVehicles;
  },
  SET_RESOURCE: (state, vehicle) => {
    state.vehicle = vehicle;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((vehicle) => {
      commit("SET_RESOURCE", vehicle);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((vehicle) => {
      commit("SET_RESOURCE", vehicle);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((vehicle) => {
      commit("SET_RESOURCE", vehicle);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  lookupApiByVin({ commit, dispatch }, params = {}) {
    return service.lookupApiByVin(params, this.$axios).then((vehicles) => {
      commit("SET_API_VEHICLES", vehicles);
    });
  },

  lookupWorstPriceByVin({ commit, dispatch }, params = {}) {
    return service
      .lookupWorstPriceByVin(params, this.$axios)
      .then((vehicles) => {
        return vehicles;
      });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  vehicle: (state) => state.vehicle,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((vehicle) => ({
      id: vehicle.id,
      name: `${vehicle.make} ${vehicle.model} - ${vehicle.series}`,
    }));
  },
  apiVehicles: (state) => state.apiVehicles,
};

const vehicles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default vehicles;

import {
  STATUS_ACTIVE,
  STATUS_CONFIRMED,
  STATUS_DELETED,
  STATUS_INACTIVE,
  TAXES_MUNICIPAL,
  TAXES_SCHOOL,
  TYPE_COMMERCIAL,
} from "../../constants/vehicles";

export default {
  VEHICLES_LIST: "Liste des véhicules",
  ADD_PROPERTY: "Ajouter une propriété",
  VIEW_PROPERTY: "Voir la propriété",
  PROPERTY_ADDED: "Propriété ajoutée",
  EDIT_PROPERTY: "Modifier une propriété",
  PROPERTY_UPDATED: "Propriété mise à jour",
  DELETE_THIS_PROPERTY: "Supprimer cette propriétée ?",
  ENABLE_THIS_PROPERTY: "Désirez-vous afficher la propriété au public ? ",
  DISABLE_THIS_PROPERTY:
    "Désirez-vous retirer l’affichage de la propriété au public ?",
  PROPERTY_DELETED: "Propriété supprimée",
  PROPERTY_ENABLE: "Propriété activée",
  PROPERTY_DISABLE: "Propriété désactivée",
  SOLD_AT: "Date de vente",
  NAME: "Nom de la propriété",
  SOLD_AMOUNT: "Montant de la vente",
  SOLD: "Montant",
  REGISTRATION_NUMBER: "Matricule",
  BATCH_NUMBER: "Lot(s)",
  BATCH: "Lot numéro",
  BATCH_NUMBERS: "Numéro(s) de lot",
  CADASTRE: "Cadastre",
  TYPE: "Type de propriété",
  EXPORT_EXCEL: "Exporter en excel",
  TAXES_DUE: "Taxes dûes",
  PROPERTIES_TYPE_COMMERCIAL: "Commercial",
  PROPERTIES_TYPE_INDUSTRIAL: "Industriel",
  PROPERTIES_TYPE_VACANT_LAND: "Terrain",
  PROPERTIES_TYPE_RESIDENTIAL: "Résidentiel",
  PROPERTIES_TYPE_LONG_COMMERCIAL: "Commercial (taxable en tout temps)",
  PROPERTIES_TYPE_LONG_INDUSTRIAL: "Industriel (taxable en tout temps)",
  PROPERTIES_TYPE_LONG_VACANT_LAND: "Terrain(taxable en tout temps)",
  PROPERTIES_TYPE_LONG_RESIDENTIAL: "Résidentiel (non taxable)",
  PROPERTIES_TAXES_MUNICIPAL: "Municipale",
  PROPERTIES_TAXES_DUE_MUNICIPAL: "Taxes dues Municipalités",
  PROPERTIES_TAXES_DUE_SCHOOL: "Taxes dues commissions scolaires",
  PROPERTIES_TAXES_SCHOOL: "Scolaire",
  PROPERTIES_STATUS_CANCEL: "Annulé",
  PROPERTIES_STATUS_PENDING: "En traitement",
  PROPERTIES_STATUS_ACTIVE: "Actif",
  PROPERTIES_STATUS_CONFIRMED: "Confirmé",
  EXPORT_CONFIRMED: "Exporter confirmé",
  EXPORT_ALL: "Exporter tout",
  PROPERTIES_STATUS_APPROVED: "Approuvé",
  MRC_FEES: "Frais de la MRC",
  PROPERTIES_ACTIVE: "Activé",
  DIRECT_MAIL: "Publipostages",
  SELECTED_DIRECT_MAIL: "Publipostages sélectionnés",
  STATE: "Etat",
  DESIGNATION: "Désignation",
  PROPERTIES_DISABLE: "Désactivé",
  SALE_CONFIRMED_AT: "Date confirmée",
  ACQUISITION_NUMBER: "Numéro d’acquisition",
  ACQUISITION_METHODE: "Mode d’acquisition",
  PROPERTY_NUMBER: "Numéro de dossier",
  PROPERTY_TAX_DUE: "Total taxes dûes",
  PROPERTY_TOTAL_DUE: "Total dû",
  EXPORT_FILE_NAME_OF: "Proprietes_du_",
  LETTERS_FILE_NAME_OF: "Lettres_citoyens_du_",
  THUMBNAILS_FILE_NAME_OF: "Vignettes_du_",
  PRINT: "Imprimer",
  PRINT_ALL: "Imprimer tout",
  CHANGE_VISIBILITY: "Changer l'affichage",
  CHANGE_READY_STATUS:
    "Vous êtes sur le point de changer le statut de cette propriété qui était prête à l’encan.",
  CONFIRMATION_QUESTION:
    "Êtes-vous certain de vouloir changer le statut à “{status}” ?",
  DISPLAY_ON_WEB_SITE: "Affiché sur site web ?",
  CANCEL_REASON: "Raison d'annulation",
  PRINT_ALL_TOOLTIP:
    "Imprimer les lettres citoyens pour toutes les propriétés dont le statut est confirmé",
  PRINT_SELECTION: "Imp. sélection",
  PRINT_SELECTION_TOOLTIP:
    "Imprimer les lettres citoyens pour les propriétés sélectionnées dont le statut est confirmé",
  TRANSACTION_DATE: "Date de transaction",
  PAYMENT_RECEIVED_BY: "Paiement reçu par",
  PAYMENT_DETAILS: "Détail du paiement",
  PRINT_RECEIPT: "Imprimer le reçu",
  PAYMENT_INFOS: "Informations suplémentaires",
  DELETE_TRANSACTION_ON_PROPERTY:
    "Voulez vous supprimer les transactions de cette propriété ?",
  PRINT_MODAL_HEADER:
    "Veuillez sélectionner la ville et La date pour l'en-tête de la lettre",
  VEHICLE_NUMBER : "Numéro de la voiture",
  VIN : "VIN",
  YEAR : "Année",
  COLOR : "Couleur",
  VEHICLES_LIST: "Liste des véhicules",
  ADD_VEHICLE: "Ajouter un véhicule",
  VEHICLE_ADDED: "Véhicule ajouté",
  EDIT_VEHICLE: "Modifier le véhicule",
  VEHICLE_UPDATED: "Véhicule mis à jour",
  DELETE_THIS_VEHICLE: "Supprimer ce véhicule ?",
  VEHICLE_DELETED: "Véhicule supprimé",
  VIN: "Vin",
  MAKE: "Marque",
  MODEL: "Modèle",
  SERIES: "Série",
  MODEL_YEAR: "Année",
  STYLE: "Style",
  ENGINE_DESCRIPTION: "Moteur",
  FUEL_TYPE: "Type de carburant",
  TRANSMISSION: "Transmission",
  DRIVETRAIN: "Motricité",
  CLASS_CODE: "Code Type de véhicule",
  CLASS_NAME: "Type de véhicule",
  VIEW_VEHICLE: "Véhicule",
  VEHICLE_AND_VIN: "Véhicule et VIN",
  SEARCH_BY_VIN: "Trouver par VIN",
  SKU: "Numéro de stock",
  REGISTRATION_NUMBER: "Numéro de plaque",
  ODOMETER: "Odomètre",
  COLOR: "Couleur",
  VEHICLE_IDENTIFICATION: "Identification du véhicule",
  PICTURES: "Photos du véhicule",

  MAKE_VEHICLE_ESTIMATION: "Faire une estimation de véhicule",
  ESTIMATE_VEHICLE: "Estimation d'un véhicule",

  KMS: "Kilométrage",
  KMS_RATIO: "Kilométrage ratio",
  ESTIMATE: "Estimation",
  KMS_MIN: "Kilométrage min",
  KMS_MAX: "Kilométrage max",
  KMS_AVG: "Kilométrage moy",
  KMS_AVG_RATIO: "Kilométrage moy ratio",
  KMS_AVG_MIN: "Kilométrage moy min",
  KMS_AVG_MAX: "Kilométrage moy max",
  ESTIMATE_SOLD: "Prix de l'estimation",
  ESTIMATE_SOLD_ACTIVE: "Prix courant",
  ESTIMATE_SOLD_ESS: "Estimation ESS",

  VEHICLE_ESTIMATE: "Estimer",
  TRIM: "Finition",
  ODOMETER_MILEAGE: "Kilométrage de l'odomètre",
  ODOMETER_TYPE: "Type d'odomètre",
  ODOMETER_PROBLEM: "Problème d'odomètre",
  OUTSIDE_COLOR: "Couleur extérieure",
  INSIDE_COLOR: "Couleur intérieure",
  MANUFACTURER_WARRANTY_EXPIRATION: "Expiration de la garantie du fabricant",
  MECHANICAL_PROBLEM: "Problème mécanique",
  MECHANICAL_PROBLEM_DETAILS: "Détails du problème mécanique",
  LEDS: "LEDs",
  MECHANICS_PROBLEMS: "Problèmes mécaniques",
  PRIOR_DAMAGES: "Dommages antérieurs",
  CARFAX: "Carfax",
  INSPECTION_REQUIRED: "Inspection requise",
  REBUILT_STATUS: "Statut reconstruit",
  ACCIDENT_INVOLVED: "Accident impliqué",
  REPAIR_DETAILS: "Détails de la réparation",
  REPAIR_COSTS: "Coûts de réparation",
  PANELS_REPAIRED: "Panneaux réparés",
  PANEL_DETAILS: "Détails des panneaux" ,
  INTERIEUR : "Intérieur",
  ANTECEDENT_VEHICLE: "Antécédant du véhicule",
    CARFAX: "Carfax",
    INSPECTION_REQUIRED: "Inspection requise",
    REBUILT_STATUS: "Statut reconstruit",
    ACCIDENT_INVOLVED: "Le véhicule a-t-il été impliqué dans un accident",
    REPAIR_DETAILS: "Détails de la réparation d'un véhicule impliqué dans un accident",
    REPAIR_COSTS: "Coûts de réparation d'un véhicule impliqué dans un accident",
    PANELS_REPAIRED: "Y a-t-il des panneaux qui ont été réparés?",
    PANEL_DETAILS: "Si oui, donnez des détails sur les panneaux repeints, remplacés ou réparés",
    PANEL_COSTS: "Coût des panneaux repeints, remplacés ou réparés",
    SHELF_INFLATING_BAGS: "Les airbags se gonflent-ils bien?",
    VEHICLE_ORIGIN: "Le véhicule provient-il des États-Unis ou d'une autre province canadienne?",
    FURTHER_DETAILS: "Si oui, donnez plus de détails",
    SHELF_INFLATING_BAGS_WORK: "Fonctionnement des sacs gonflables d'étagère",
    ORIGIN: "Origine",
    ORIGIN_DETAILS: "Détails de l'origine"
};
